import '@shtickfest-css/shtickfest.pcss'
import 'flowbite';

document.addEventListener("DOMContentLoaded", function() {
    const anchors = document.querySelectorAll('a[href^="#"]');
    anchors.forEach(anchor => {
        anchor.addEventListener('click', function(e) {
            e.preventDefault();

            const targetId = this.getAttribute('href');
            const targetElement = document.querySelector(targetId);
            const headerHeight = document.querySelector('.sticky')?.offsetHeight;

            const offsetPosition = targetElement.offsetTop - headerHeight;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        });
    });
});
let isShrunk = false;

window.addEventListener('scroll', function() {
    const header = document.getElementById('header');

    if (window.scrollY > 50 && !isShrunk) {
        header.classList.add('shrink-header');
        isShrunk = true;
    } else if (window.scrollY <= 15 && isShrunk) {
        header.classList.remove('shrink-header');
        isShrunk = false;
    }
});